import {BrowserRouter, Routes, Route, useNavigate} from 'react-router-dom';
import React, { useEffect } from 'react';
import AboutMe from './pages/AboutMe.js';


const FallbackRoute = () => {
  let navigate = useNavigate();
  
  useEffect(() => {
    navigate('/');
  }, [navigate]);

  return null;
}
const ExternalRedirect = () => {
  useEffect(() => {
    window.location.href = 'https://usemotion.com/meet/akhil-m/meeting';
  }, []);

  return null;
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AboutMe/>}/>
        <Route path="/meeting" element={<ExternalRedirect/>}/>
        <Route path="*" element={<FallbackRoute/>}/>

      </Routes>
    </BrowserRouter>
  );
}

export default App;
