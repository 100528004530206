import React from 'react';
import BI from "../images/BI.png";
import NE from "../images/NE.png";
import HA from "../images/HA.png";
import WL from "../images/WL.png"; 
import CF from "../images/CF.png"; 
import AR from "../images/AR.png"; 
import BB from "../images/BB.png"; 
import HH from "../images/HH10.png"; 
import TV from "../images/TV.png"; 
import ICPC from "../images/ICPC.png"; 
import HeadShot from "../images/Headshotweb.jpeg"; 
import DSP from "../images/DSP.jpg";
import DA from "../images/DA.jpeg";
import WorkCard from '../components/WorkCard';
import LangCard from '../components/LangCard';

import FlaskIcon from "../icons/FlaskIcon.png";
import FlutterIcon from "../icons/FlutterIcon.png";
import GoIcon from "../icons/GoIcon.png";
import JavaIcon from "../icons/JavaIcon.png";
import MongoDBIcon from "../icons/MongoDBIcon.png";
import PythonIcon from "../icons/PythonIcon.png";
import ReactIcon from "../icons/ReactIcon.png";
import SwiftIcon from "../icons/SwiftIcon.png";
import TailWindIcon from "../icons/TailWindIcon.png";
import LeftArrow from "../icons/LeftArrowIcon.png";
import GithubIcon from "../icons/GithubIcon.png";
import MailIcon from "../icons/MailIcon.png";
import LinkedInIcon from "../icons/LinkedInIcon.png";


const exps = [
    {
        title: 'Better Innovations',
        imageSrc: BI,
        content: ['- Project Manager', '- Responsible for $461,000 in Revenue', '- GO, React, Python, Flask'],
        redir: "https://isp.butterinnovations.com/index.php/store/proxy-subnet",
        visit: 'true'
    },
    {
        title: 'AIN Enterprises',
        imageSrc: NE,
        content: ['- Co-Founder', '- Led 4 Employees ', '- GO (Wails), React, Python'],
        redir: "https://neuralaio.com/",
        visit: 'true'
    },
    {
        title: 'HayhaBots Incorporated',
        imageSrc: HA,
        content: ['- DevOps Intern', '- Dual Stack Node Project', '- iDRAC, IPV6/ IPV4'],
        redir: "https://www.hayhabots.com/",
        visit: 'true'
    },
    {
        title: 'Whims',
        imageSrc: WL,
        content: ['2nd Place CS 97 UCLA'],
        redir: "https://www.onawhims.com/",
        visit: 'true'
    },
    {
        title: 'Trade Content Delivery',
        imageSrc: BB,
        content: ['- Developed + Delivered Crypto Strategies', '- Twitter, Discord, Python', '- $129,000,000+ Influenced Volume']
    },
    {
        title: 'Helping Hand',
        imageSrc: HH,
        content: ['Click "Visit Project" Above to Learn More'],
        redir: "https://devpost.com/software/helping-hand-vif9x8",
        visit: 'true'
    },
    {
        title: 'Competitive Programming Trainer',
        imageSrc: ICPC,
        content: ['- ICPC Club Peer Trainer', '- Placed 3rd @ SJSU', '- Placed 13th @ PNW '],
        redir: "https://drive.google.com/file/d/1Iu41o_6gsMzx6n166plQT8Hyq4NFgubn/view?usp=sharing",
        visit: 'true'
    },
    {
        title: 'Differential Equations Paper',
        imageSrc: DA,
        content: ['Click "Visit Project" Above to Learn More'],
        redir: "https://drive.google.com/file/d/10DOCBKojkGXg0M60HdZ82PrI8asRlrGV/view",
        visit: 'true'
    }, 
    {
        title: 'Price Action Tooling',
        imageSrc: TV,
        content: ['- Developed Indicators', '- PineScript V5', '- 15+ Users ']
    },
    {
        title: 'Cedar Fair',
        imageSrc: CF,
        content: ['Sales Associate']
    },
    {
        title: 'AYSO Referee',
        imageSrc: AR,
        content: ['Refereed 8 Years']
    },
    {
        title: 'VP Finance - DSP',
        imageSrc: DSP,
        content: ['- DSP Vice President of Finance', '- Managed club funds with SJSU AS']
    },
  ];

const techs = [
    {
        language: 'React',
        imageSrc: ReactIcon
    },
    {
        language: 'Tailwind',
        imageSrc: TailWindIcon
    },
    {
        language: 'Java',
        imageSrc: JavaIcon
    },
    {
        language: 'Mongo',
        imageSrc: MongoDBIcon
    },
    {
        language: 'Python',
        imageSrc: PythonIcon
    },
    {
        language: 'Golang',
        imageSrc: GoIcon
    },
    {
        language: 'Flutter',
        imageSrc: FlutterIcon
    },
    {
        language: 'Swift',
        imageSrc: SwiftIcon
    },
    {
        language: 'Flask',
        imageSrc: FlaskIcon
    },
]


const AboutMe = () => {
    return (
        <div className=" bg-[#141519] flex flex-col font-manrope overflow-hidden min-h-screen">
            <div className='flex flex-col items-center justify-center w-11/12 p-6 mx-auto text-white sm:w-3/4 md:w-2/3 lg:w-1/2'>
                <div className='justify-center'>
                    <h1 className='mb-6 text-4xl font-bold text-center'>
                        Hi, I’m <span className="text-[#1DBF85]">Akhil</span>!
                    </h1>
                    <div className='flex justify-center mb-6'>
                        <img class="object-cover rounded-full p-2 h-[200px] border border-gray-500" src={HeadShot} alt="Akhil's Headshot" />
                    </div>
                    <p className='text-lg text-center'>
                        I am a senior majoring in Software Engineering at San Jose State University.
                    </p>
                </div>

                <div className='mt-12'>
                    <h1 className='m-6 text-3xl font-bold text-center'>Experiences & Projects</h1>
                    <div className='grid justify-start grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3'>
                        {exps.map((item, index) => (
                        <WorkCard
                            key={index}
                            title={item.title}
                            imageSrc={item.imageSrc}
                            content={item.content}
                            redir={item.redir} 
                            visit={item.visit}
                        />
                        ))}
                    </div>
                </div>

                <div className='mt-12'>
                    <h1 className='m-6 text-3xl font-bold text-center'>Technologies</h1>
                    <div className='grid justify-start grid-cols-2 gap-6 lg:grid-cols-3 xl:grid-cols-4'>
                        {techs.map((item, index) => (
                        <LangCard
                            key={index}
                            language={item.language}
                            imageSrc={item.imageSrc}
                        />
                        ))}
                    </div>
                </div>

                <div className='flex flex-col items-center justify-center w-full mt-12'>
                    <div className='flex flex-row items-center justify-center p-4 mt-4'>
                        <a 
                            href="https://drive.google.com/file/d/1_ONfNR71BJjF2GmQ3bYiXovXCHfbSGLS/view?usp=sharing" 
                            target="_blank"
                            rel="noopener noreferrer"
                            className='text-blue-400 underline transition-colors hover:text-blue-600'
                        >
                            View my resume here
                        </a>
                        <img class="object-cover ml-3 h-5" src={LeftArrow} alt="Left Arrow Icon" />
                    </div>

                    <div className='flex flex-col justify-between w-full pt-4 sm:flex-row'>
                        <h2 className='text-xl font-bold'>Akhil Manjesh</h2>
                        <div className='flex flex-row pt-2'>
                            <a href="https://www.linkedin.com/in/akhilmanjesh/" className='mr-2'>
                                <img class="object-cover h-[25px]" src={LinkedInIcon} alt="LinkedIn" />
                            </a>
                            <a href="https://github.com/akhilmanjesh/" className='mr-2'>
                                <img class="object-cover h-[25px]" src={GithubIcon} alt="Github" />
                            </a>
                            <a href="mailto:akhil.manjesh@sjsu.edu?subject=Email Akhil" className=''>
                                <img class="object-cover h-[25px]" src={MailIcon} alt="Email" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        );
}

export default AboutMe;
